<template>
    <div class="l-default bg-nl-brand-1 t-forgotpassword">
        <Sprite />
        <svg
            class="l-default__background"
            viewBox="0 0 1920 682"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            preserveAspectRatio="none"
        >
            <path
                d="M-12 -17H1933V573.652C1424.28 764.929 996.99 568.64 635.162 568.64C379.98 568.64 191.077 588.689 -12 681V-17Z"
                fill="#4942CA"
                stroke="#E2E6F8"
                stroke-linecap="round"
            />
        </svg>

        <header class="o-header">
            <div class="o-header__logo">
                <a href="/" title="SEM Juice">
                    <svg class="a-svg -logo" aria-hidden="true" focusable="false">
                        <use xlink:href="#icon-nl-logo"></use>
                    </svg>
                </a>
            </div>
        </header>

        <main>
            <div class="l-default__container">
                <div>
                    <router-link to="/login"
                                 class="a-button -no-transparent -small l-default__back text-nl-white no-underline-hover color-white-hover">
                        <svg class="a-svg" aria-hidden="true" focusable="false">
                            <use xlink:href="#icon-chevron-left"></use>
                        </svg>
                        <span>Retour</span>
                    </router-link>
                </div>
                <div class="l-default__panel">
                    <div class="o-panel">
                        <section class="o-panel__main">
                            <h2 class="text-l o-panel__main--title">Mot de passe oublié ?</h2>
                            <p class="text-pm text-nl-neutral-6">
                                Indiquez l’adresse e-mail de votre compte ci-dessous. Nous vous enverrons un lien pour
                                réinitialiser votre mot de passe.
                            </p>
                            <form action="" method="POST" class="o-panel__form">
                                <div class="m-form__fieldset">
                                    <div>
                                        <label for="email" class="text-label"
                                        >E-mail <span class="required">*</span></label
                                        >
                                        <input
                                            v-model="email"
                                            id="email"
                                            class="a-input -email"
                                            name="username"
                                            placeholder="Indiquez l’adresse e-mail de votre compte"
                                            type="email"
                                        />
                                    </div>
                                </div>
                                <button class="a-button -primary" @click.prevent="doForgetPassword">
                                    <span>Réinitialiser mon mot de passe NextLevel</span>
                                    <svg class="a-svg arrow" aria-hidden="true" focusable="false">
                                        <use xlink:href="#icon-arrow-right"></use>
                                    </svg>
                                </button>
                            </form>
                        </section>
                    </div>
                    <img class="l-default__illu -lock" src="@/assets/img-v2/lock.svg" alt="" width="120" />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import Sprite from '@/views/Svg/Sprite.vue'
    import Toaster from '@/components/UI/Toaster'
    import { mapActions } from 'vuex'

    export default {
        name: 'ForgottenPassword',
        title: 'register.forgotten-password',
        components: {
            Sprite,
            Toaster
        },
        data: function() {
            return {
                message: {},
                email: null
            }
        },
        methods: {
            ...mapActions('auth', ['resetPassword']),
            doForgetPassword() {
                this.loader = true
                this.resetPassword(this.email)
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title:  this.$t('oubli.resetConfirmationTitle'),
                            message: this.$t('oubli.resetConfirmationMessage')
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loader = false
                    })
            }
        },
        mounted() {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = "/css/app.css"
            link.id = 'customCss';

            document.head.appendChild(link);
        }
    }
</script>


